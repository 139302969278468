registerProcessor(
  'SlewLimiterProcessor',
  class SlewLimiterProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'signal',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
        {
          name: 'time',
          defaultValue: 0,
          minValue: 0,
          automationRate: 'k-rate',
        },
      ];
    }

    lastValue = 0;

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      { signal: [signal], time: [time] }: { [param: string]: Float32Array },
    ): boolean {
      if (time === 0) {
        this.lastValue = signal;
        output.fill(signal);
      } else {
        const maxDelta = (output.length / sampleRate) * (1200 / time);
        const delta = signal - this.lastValue;

        if (delta < -maxDelta) {
          this.lastValue = this.lastValue - maxDelta;
        } else if (delta > maxDelta) {
          this.lastValue = this.lastValue + maxDelta;
        } else {
          this.lastValue = signal;
        }

        output.fill(this.lastValue);
      }

      return true;
    }
  },
);
